import { useQuery } from '@apollo/react-hooks';
import { Button, InputAdornment } from '@mui/material';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import { default as classnames, default as classNames } from 'classnames';
import LoadingSpinner from 'components/common/LoadingSpinner';
import TabPanel from 'components/common/TabPanel';
import AddObservationAiPocDialog from 'components/observations/AddObservationAiPocDialog';
import ObservationCardsList from 'components/observations/ObservationCardsList';
import ObservationsDashboardFilterMenu from 'components/observations/ObservationsDashboardFilterMenu';
import { OBSERVATION_SEARCH } from 'graphql/observations';
import withAuthorization from 'hocs/withAuthorization';
import useCurrentUser from 'hooks/useCurrentUser';
import useDebounce from 'hooks/useDebounce';
import useObservationCounts from 'hooks/useObservationCounts';
import useRoles from 'hooks/useRoles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import withQueryParams from 'react-router-query-params';
import { ClimbingBoxLoader } from 'react-spinners';
import StyledFab from 'shared/Buttons/Fab';
import StyledOfflineBanner from 'shared/OfflineBanner';
import useIsManualOfflineModeOn from 'store/manualOfflineMode';
import useObservationListState from 'store/observationListState';
import useOfflineSync from 'store/offlineSync';
import useIsOnline from 'store/onlineDetection';
import useObservationStyles from './useObservationsStyles';

const MY_OBSERVATIONS_TAB = 'myobservations';
const ALL_HP_OBSERVATIONS_TAB = 'allhpobservations';
const PENDING_OBSERVATIONS_TAB = 'pending';

const allowedTabValues = [
  MY_OBSERVATIONS_TAB,
  ALL_HP_OBSERVATIONS_TAB,
  PENDING_OBSERVATIONS_TAB
];

const validateTabParam = search => {
  const params = new URLSearchParams(search);
  const tab = params.get('tab');
  return allowedTabValues.includes(tab?.toLowerCase());
};

// Helpers
const resetUpdateQuery = (
  _previousResult,
  { fetchMoreResult, _queryVariables }
) => {
  return fetchMoreResult;
};

const a11yProps = index => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
};

const getApiFilter = filterState => {
  const filter = {};

  for (const [key, value] of Object.entries(filterState)) {
    switch (key) {
      case 'createdAfter':
        if (value === 'lastWeek') {
          const oneWeekAgo = moment().subtract(7, 'days');
          filter.createdAfter = oneWeekAgo.startOf('day').toISOString();
        } else if (value === 'lastMonth') {
          const thirtyDaysAgo = moment().subtract(30, 'days');
          filter.createdAfter = thirtyDaysAgo.startOf('day').toISOString();
        } else if (value) {
          filter.createdAfter = value;
        }
        break;
      case 'createdBefore':
        if (value) {
          filter.createdBefore = value;
        }
        break;
      default:
        filter[key] = value;
        break;
    }
  }

  return filter;
};

const getApiSortOrder = sortState => {
  return sortState.name && sortState.direction
    ? [
        {
          [sortState.name]: sortState.direction.toUpperCase()
        }
      ]
    : null;
};

// Making this global to get around issues with async role loading.
var isAdminTypeRole = false;
var shouldSkipAllHpObseravtionsQuery = true;
var shouldSkipMyObservationsQuery = true;

const ObservationsDashboardAiPocPage = ({ setQueryParams }) => {
  const styles = useObservationStyles();
  const classes = styles();
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  let { search: locationSearch } = useLocation();
  const { projectId } = useParams();
  const { currentUser } = useCurrentUser();
  const {
    isAdminOnProject,
    isEnterpriseAdmin,
    isLoading: isLoadingRoles
  } = useRoles();
  isAdminTypeRole = isEnterpriseAdmin || isAdminOnProject(projectId);
  const {
    myOpenObservationsCount,
    allOpenObservationsCount
  } = useObservationCounts(projectId, isAdminTypeRole);
  const params = new URLSearchParams(locationSearch);
  const { isOnline } = useIsOnline();
  const {
    offlineSyncState: { observations: pendingObservationsState }
  } = useOfflineSync();
  const [
    observationListState,
    { handleObservationListStateChange }
  ] = useObservationListState();
  const { isOfflineModeEnabled } = useIsManualOfflineModeOn();

  const [initialObservationListState] = useState(observationListState);
  const [search, setSearch] = useState(observationListState.search);
  const [shouldShowLoader, setShouldShowLoader] = useState(true);
  const [
    initialQueriesHaveBeenCalled,
    setInitialQueriesHaveBeenCalled
  ] = useState(false);

  const allowedTabs = [
    MY_OBSERVATIONS_TAB,
    ...(isAdminTypeRole ? [ALL_HP_OBSERVATIONS_TAB] : []),
    PENDING_OBSERVATIONS_TAB
  ];
  const tabParam = params.get('tab')?.toLowerCase() || MY_OBSERVATIONS_TAB;
  const tabIndex = Math.max(0, allowedTabValues.indexOf(tabParam));
  const [activeTab, setActiveTab] = useState(tabIndex);
  const [activeTabName, setActiveTabName] = useState(
    allowedTabValues[tabIndex] ?? null
  );

  useEffect(() => {
    // This can perform navigation, so in order to keep the render function a pure function, we perform it in a useEffect.
    if (!isLoadingRoles && !allowedTabs.includes(tabParam)) {
      history.push({ search: `?tab=${allowedTabs[0]}` });
    }
  }, [allowedTabs, isLoadingRoles, tabParam, history]);

  const [addObservationDialogIsOpen, toggleAddObservationDialog] = useState(
    false
  );

  // Get the initial data
  shouldSkipMyObservationsQuery =
    !isOnline || !allowedTabs.includes(MY_OBSERVATIONS_TAB);
  const {
    data: myObservationsData,
    loading: isMyObsLoading,
    fetchMore: fetchMoreMyObservations
  } = useQuery(OBSERVATION_SEARCH, {
    skip: shouldSkipMyObservationsQuery,
    variables: {
      projectId,
      ownObservations: true,
      first: initialObservationListState.first,
      skip: 0,
      search: initialObservationListState.search,
      filter: getApiFilter(initialObservationListState.filter),
      order: getApiSortOrder(initialObservationListState.order)
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });
  shouldSkipAllHpObseravtionsQuery =
    !isAdminTypeRole ||
    !isOnline ||
    !allowedTabs.includes(ALL_HP_OBSERVATIONS_TAB);
  const {
    data: allHpObservationsData,
    loading: isAllObsLoading,
    fetchMore: fetchMoreAllHpObservations
  } = useQuery(OBSERVATION_SEARCH, {
    skip: shouldSkipAllHpObseravtionsQuery,
    variables: {
      projectId,
      first: initialObservationListState.first,
      skip: 0,
      search: initialObservationListState.search,
      filter: getApiFilter(initialObservationListState.filter),
      order: getApiSortOrder(initialObservationListState.order)
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    const shouldDisableFullscreenLoader = isAdminTypeRole
      ? !initialQueriesHaveBeenCalled &&
        (shouldSkipMyObservationsQuery || myObservationsData) &&
        (shouldSkipAllHpObseravtionsQuery || allHpObservationsData)
      : !initialQueriesHaveBeenCalled && myObservationsData;

    if (shouldDisableFullscreenLoader) {
      setShouldShowLoader(false);
      setInitialQueriesHaveBeenCalled(true);
    }
  }, [
    allHpObservationsData,
    initialQueriesHaveBeenCalled,
    isAdminTypeRole,
    myObservationsData
  ]);

  useEffect(() => {
    if (
      !initialQueriesHaveBeenCalled &&
      (observationListState.myObservationsSkip > 0 ||
        observationListState.allHpObservationsSkip > 0)
    ) {
      handleObservationListStateChange({
        ...observationListState,
        myObservationsSkip: 0,
        allHpObservationsSkip: 0
      });
    }
  }, [
    handleObservationListStateChange,
    initialQueriesHaveBeenCalled,
    observationListState
  ]);

  // Observations
  const myObservations =
    myObservationsData?.observationSearch?.observations ?? [];

  const allHpObservations =
    allHpObservationsData?.observationSearch?.observations ?? [];

  const pendingObservationsData =
    pendingObservationsState?.pending?.filter(
      pendingObservation => pendingObservation?.creator.upn === currentUser?.upn
    ) ?? [];

  const pendingObservations = pendingObservationsData;

  // Counts
  const pendingObservationCount = pendingObservationsData.length;

  const myObservationsTotalCount =
    myObservationsData?.observationSearch?.total ?? 0;

  const observationsTotalCount =
    allHpObservationsData?.observationSearch?.total ?? 0;

  const pendingObservationsTotalCount = pendingObservations.length;

  // Handlers
  const loadMoreMyObservations = (variables, shouldReset = false) => {
    if (shouldSkipMyObservationsQuery) {
      return;
    }
    if (shouldReset) {
      setShouldShowLoader(true);
    }

    return fetchMoreMyObservations({
      variables,
      updateQuery: shouldReset
        ? resetUpdateQuery
        : (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return previousResult;
            return Object.assign({}, previousResult, {
              observationSearch: {
                ...previousResult.observationSearch,
                ...fetchMoreResult.observationSearch,
                total: fetchMoreResult.observationSearch.total,
                observations: [
                  ...previousResult.observationSearch.observations,
                  ...fetchMoreResult.observationSearch.observations
                ]
              }
            });
          }
    }).then(() => {
      setShouldShowLoader(false);
    });
  };

  const loadMoreAllHpObservations = (variables, shouldReset = false) => {
    if (shouldSkipAllHpObseravtionsQuery) {
      return;
    }
    if (shouldReset) {
      setShouldShowLoader(true);
    }
    return fetchMoreAllHpObservations({
      variables,
      updateQuery: shouldReset
        ? resetUpdateQuery
        : (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return previousResult;
            return Object.assign({}, previousResult, {
              observationSearch: {
                ...previousResult.observationSearch,
                ...fetchMoreResult.observationSearch,
                total: fetchMoreResult.observationSearch.total,
                observations: [
                  ...previousResult.observationSearch.observations,
                  ...fetchMoreResult.observationSearch.observations
                ]
              }
            });
          }
    }).then(() => {
      setShouldShowLoader(false);
    });
  };

  const loadMore = (type, variables, shouldReset = false) => {
    if (type === 'myObservations') {
      loadMoreMyObservations(variables, shouldReset);
    } else if (type === 'allHpObservations') {
      loadMoreAllHpObservations(variables, shouldReset);
    } else {
      console.error(`Cannot handle loadMore of type: "${type}"`);
    }
  };

  const handleLoadNextPage = type => {
    const newState = {};
    let skip = observationListState.first;

    switch (type) {
      case 'myObservations':
        skip = skip + observationListState.myObservationsSkip;
        newState.myObservationsSkip = skip;
        break;
      case 'allHpObservations':
        skip = skip + observationListState.allHpObservationsSkip;
        newState.allHpObservationsSkip = skip;
        break;
      default:
        console.error(`Cannot handle loadMore for type: "${type}"`);
    }

    handleObservationListStateChange({
      ...observationListState,
      ...newState
    });

    const variables = {
      first: observationListState.first,
      search: observationListState.search,
      filter: getApiFilter(observationListState.filter),
      skip
    };

    loadMore(type, variables);
  };

  const resetAndRefetch = () => {
    handleObservationListStateChange({
      ...observationListState,
      myObservationsSkip: 0,
      allHpObservationsSkip: 0
    });

    const variables = {
      first: observationListState.first,
      search: observationListState.search,
      skip: 0,
      filter: getApiFilter(observationListState.filter),
      order: getApiSortOrder(observationListState.order)
    };

    loadMore('myObservations', variables, true);
    if (isAdminTypeRole) {
      loadMore('allHpObservations', variables, true);
    }
  };

  const handleDebouncedSearchChange = ({ search, state }) => {
    handleObservationListStateChange({
      ...state,
      search,
      myObservationsSkip: 0,
      allHpObservationsSkip: 0
    });

    const variables = {
      first: state.first,
      filter: getApiFilter(state.filter),
      skip: 0,
      search,
      order: getApiSortOrder(state.order)
    };

    loadMore('myObservations', variables, true);
    if (isAdminTypeRole) {
      loadMore('allHpObservations', variables, true);
    }
  };

  const { debounced: debouncedHandleSearchChange } = useDebounce(
    handleDebouncedSearchChange
  );

  const handleSearchChange = event => {
    const search = event ? event.target.value : '';

    setSearch(search);
    debouncedHandleSearchChange({
      search,
      state: observationListState
    });
  };

  const handleSortChange = () => {
    const newDirection =
      observationListState.order.direction === 'asc' ? 'desc' : 'asc';

    const newOrder = {
      ...observationListState.order,
      direction: newDirection
    };
    handleObservationListStateChange({
      ...observationListState,
      order: newOrder
    });

    const variables = {
      first: observationListState.first,
      skip: 0,
      search: observationListState.search,
      filter: getApiFilter(observationListState.filter),
      order: getApiSortOrder(newOrder)
    };

    loadMore('myObservations', variables, true);
    if (isAdminTypeRole) {
      loadMore('allHpObservations', variables, true);
    }
  };

  const handleFilterChange = filterState => {
    handleObservationListStateChange({
      ...observationListState,
      filter: filterState
    });

    const variables = {
      first: observationListState.first,
      skip: 0,
      search: observationListState.search,
      filter: getApiFilter(filterState),
      order: getApiSortOrder(observationListState.order)
    };

    loadMore('myObservations', variables, true);
    if (isAdminTypeRole) {
      loadMore('allHpObservations', variables, true);
    }
  };

  const myObservationsLabel = () => {
    if (myOpenObservationsCount > 0) {
      return (
        <Tooltip
          title={t('myObservations.tab.openObservationsCount', {
            count: myOpenObservationsCount
          })}>
          <Badge
            badgeContent={myOpenObservationsCount}
            data-testid="my-open-observations-badge"
            value={myOpenObservationsCount}
            classes={{
              badge:
                activeTab === allowedTabs.indexOf(MY_OBSERVATIONS_TAB)
                  ? classes.redBadge
                  : classes.whiteBadge
            }}>
            <Typography className={classes.tabLabel}>
              {t('myObservations.tab.myObservations')}
            </Typography>
          </Badge>
        </Tooltip>
      );
    } else {
      return (
        <Typography className={classes.tabLabel}>
          {t('myObservations.tab.myObservations')}
        </Typography>
      );
    }
  };

  const allHpObservationsLabel = () => {
    if (allOpenObservationsCount > 0) {
      return (
        <Tooltip
          title={t('myObservations.tab.openObservationsCount', {
            count: allOpenObservationsCount
          })}>
          <Badge
            badgeContent={allOpenObservationsCount}
            data-testid="all-open-observations-badge"
            value={allOpenObservationsCount}
            classes={{
              badge:
                activeTab !== allowedTabs.indexOf(ALL_HP_OBSERVATIONS_TAB)
                  ? classes.whiteBadge
                  : classes.redBadge
            }}>
            <Typography className={classes.tabLabel}>
              {t('myObservations.tab.allHpObservations')}
            </Typography>
          </Badge>
        </Tooltip>
      );
    } else {
      return (
        <Typography className={classes.tabLabel}>
          {t('myObservations.tab.allHpObservations')}
        </Typography>
      );
    }
  };

  const pendingObservationsLabel = () => {
    if (pendingObservationCount > 0) {
      return (
        <Tooltip
          title={t('myObservations.tab.openObservationsCount', {
            count: pendingObservationCount
          })}>
          <Badge
            badgeContent={pendingObservationCount}
            data-testid="pending-open-observations-badge"
            value={pendingObservationCount}
            classes={{
              badge:
                activeTab !== allowedTabs.indexOf(PENDING_OBSERVATIONS_TAB)
                  ? classes.whiteBadge
                  : classes.redBadge
            }}>
            <Typography className={classes.tabLabel}>
              {t('myObservations.tab.pendingObservations')}
            </Typography>
          </Badge>
        </Tooltip>
      );
    } else {
      return (
        <Typography className={classes.tabLabel}>
          {t('myObservations.tab.pendingObservations')}
        </Typography>
      );
    }
  };

  const tabs = [];
  if (allowedTabs.includes(MY_OBSERVATIONS_TAB)) {
    tabs.push({
      isActiveTab: activeTab === allowedTabs.indexOf(MY_OBSERVATIONS_TAB),
      label: myObservationsLabel(),
      content: (() => {
        if (!isOnline) {
          return (
            <div className={classes.noObservationsMessage}>
              <Typography>
                {!isOfflineModeEnabled
                  ? t('dashboardPage.observationsUnavailableOffline')
                  : t('dashboardPage.offline')}
              </Typography>
            </div>
          );
        } else {
          if (myObservations?.length < 1) {
            if (
              observationListState.hasActiveFilters ||
              observationListState.search
            ) {
              return (
                <div className={classes.noObservationsMessage}>
                  <Typography>{t('dashboardPage.noFiltersMatch')}</Typography>
                </div>
              );
            } else {
              return (
                <div className={classes.noObservationsMessage}>
                  <Typography>
                    {t('dashboardPage.noPersonalObservations')}
                  </Typography>
                </div>
              );
            }
          } else {
            return (
              <ObservationCardsList
                observations={myObservations}
                loadMore={() => handleLoadNextPage('myObservations')}
                type={'myObservations'}
                total={myObservationsTotalCount}
                isLoading={shouldShowLoader}
                refetchCurrentQueries={resetAndRefetch}
                compact={true}
              />
            );
          }
        }
      })()
    });
  }
  if (isAdminTypeRole && allowedTabs.includes(ALL_HP_OBSERVATIONS_TAB)) {
    tabs.push({
      isActiveTab: activeTab === allowedTabs.indexOf(ALL_HP_OBSERVATIONS_TAB),
      label: allHpObservationsLabel(),

      content: (() => {
        if (!isOnline) {
          return (
            <div className={classes.noObservationsMessage}>
              <Typography>
                {' '}
                {!isOfflineModeEnabled
                  ? t('dashboardPage.observationsUnavailableOffline')
                  : t('dashboardPage.offline')}
              </Typography>
            </div>
          );
        } else {
          if (allHpObservations?.length < 1) {
            if (
              observationListState.hasActiveFilters ||
              observationListState.search
            ) {
              return (
                <div className={classes.noObservationsMessage}>
                  <Typography>{t('dashboardPage.noFiltersMatch')}</Typography>
                </div>
              );
            }
            return (
              <div className={classes.noObservationsMessage}>
                <Typography>{t('dashboardPage.noAllObservations')}</Typography>
              </div>
            );
          } else {
            return (
              <ObservationCardsList
                observations={allHpObservations}
                loadMore={() => handleLoadNextPage('allHpObservations')}
                type={'allHpObservations'}
                total={observationsTotalCount}
                isLoading={shouldShowLoader}
                refetchCurrentQueries={resetAndRefetch}
                compact={true}
              />
            );
          }
        }
      })()
    });
  }
  if (allowedTabs.includes(PENDING_OBSERVATIONS_TAB)) {
    tabs.push({
      isActiveTab: activeTab === allowedTabs.indexOf(PENDING_OBSERVATIONS_TAB),
      label: pendingObservationsLabel(),
      content: (() => {
        if (pendingObservations.length > 0) {
          if (!pendingObservationsState.isSyncing) {
            return (
              <ObservationCardsList
                observations={pendingObservations}
                type={'pending'}
                deletable={true}
                refetchCurrentQueries={resetAndRefetch}
                compact={true}
              />
            );
          } else {
            return (
              <Grid
                container
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                data-testid="loading-spinner"
                className={classes.loadingSpinner}>
                <Grid item>
                  <ClimbingBoxLoader
                    color={theme.palette.primary.main}
                    loading={true}
                    size={10}
                  />
                </Grid>
                <Grid item>
                  <Typography color="primary">
                    {t('observationDashboardPage.syncing.title')}
                  </Typography>
                </Grid>
              </Grid>
            );
          }
        } else {
          // TODO: Re-implement once there is client-side filters/search for pending
          // if (
          //   observationListState.hasActiveFilters ||
          //   observationListState.search
          // ) {
          //   return (
          //     <div className={classes.noObservationsMessage}>
          //       <Typography>{t('dashboardPage.noFiltersMatch')}</Typography>
          //     </div>
          //   );
          // }

          return (
            <div className={classes.noObservationsMessage}>
              <Typography>
                {!isOfflineModeEnabled && !isOnline
                  ? t('dashboardPage.observationsUnavailableOffline')
                  : t('dashboardPage.noPendingObservations')}
              </Typography>
            </div>
          );
        }
      })()
    });
  }

  const getTotal = () => {
    switch (activeTabName) {
      case MY_OBSERVATIONS_TAB:
        return myObservationsTotalCount;
      case ALL_HP_OBSERVATIONS_TAB:
        return observationsTotalCount;
      case PENDING_OBSERVATIONS_TAB:
        return pendingObservationsTotalCount;
      default:
        return null;
    }
  };

  const handleTabChange = (_, newValue) => {
    const tab = allowedTabs[newValue];
    setActiveTabName(tab);
    setActiveTab(newValue);
    if (isOnline) {
      setQueryParams({ tab });
    }
  };

  if (
    (isMyObsLoading || isAllObsLoading) &&
    !initialQueriesHaveBeenCalled &&
    shouldShowLoader
  ) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {!isOnline && (
        <StyledOfflineBanner
          content={
            !isOfflineModeEnabled
              ? t('dashboardPage.offlineBanner.enabledOfflineMessage')
              : t('dashboardPage.offlineBanner.content')
          }
        />
      )}
      <main role="main">
        <Box
          className={classes.root}
          sx={theme => ({
            top: '112px',
            [theme.breakpoints.down('sm')]: { top: '57px' },
            left: '0px'
          })}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.filterMenuContainer}>
            <Grid item>
              <Typography
                color="textPrimary"
                variant="overline"
                className={classes.totalResults}>
                {t('dashboardPage.totalResults.label')} {getTotal()}
              </Typography>
            </Grid>
            <Grid item>
              <img
                src={theme.safeLogoMobile}
                alt={t('dashboardPage.safeLogo')}
              />
            </Grid>
            <Grid item>
              {activeTabName !== PENDING_OBSERVATIONS_TAB && (
                <ObservationsDashboardFilterMenu
                  handleChange={handleFilterChange}
                />
              )}
            </Grid>
          </Grid>

          <StyledFab
            aria-label={t('dashboardPage.addObservation')}
            onClick={() => toggleAddObservationDialog(true)}
            icon={<AddIcon />}
            disabled={!isOnline && !isOfflineModeEnabled}
            tooltipMessage={
              !isOnline && !isOfflineModeEnabled
                ? t('dashboardPage.creationDisabled')
                : ''
            }
          />

          <Grid
            container
            justifyContent="center"
            alignItems="flex-start"
            className={classes.container}>
            <Grid
              item
              xs={12}
              sm={12}
              md={10}
              lg={10}
              style={{ width: '100%' }}>
              <Tabs
                sx={{ height: '48px' }}
                data-testid="observation-tabs"
                value={activeTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="tabs">
                {tabs.map((tab, index) => (
                  <Tab
                    sx={{
                      height: '48px',
                      margin: '0px !important',
                      marginTop: '-12px !important',
                      padding: '0px !important',
                      paddingRight: '35px !important'
                    }}
                    key={index}
                    label={tab.label}
                    className={classNames(
                      classes.tab,
                      {
                        [`${classes.redTab}`]: tab.isActiveTab
                      },
                      {
                        [`${classes.whiteTab}`]: !tab.isActiveTab
                      }
                    )}
                    {...a11yProps(index)}
                  />
                ))}
              </Tabs>
              {activeTabName !== PENDING_OBSERVATIONS_TAB && (
                <Grid
                  sx={{
                    padding: '0px !important',
                    paddingBottom: '12px !important'
                  }}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.searchContainer}>
                  <Grid item>
                    <TextField
                      className={classes.searchInput}
                      value={search}
                      onChange={handleSearchChange}
                      color="primary"
                      placeholder={t('dashboardPage.searchObservations')}
                      margin="dense"
                      variant="standard"
                      inputProps={{
                        'aria-label': t('dashboardPage.searchFieldLabel'),
                        'aria-required': false
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon
                              aria-label={t('dashboardPage.searchButton')}
                            />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {observationListState.search && (
                              <IconButton
                                aria-label={t('dashboardPage.clearAlt')}
                                size="small"
                                onClick={() => handleSearchChange('')}
                                className={classes.icon}>
                                <ClearIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        )
                      }}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center">
                      <Grid item>
                        <SortIcon
                          role="presentation"
                          className={classes.sortIcon}
                        />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.sortLabel}>
                          {t('dashboardPage.sortLabel')}{' '}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={handleSortChange}
                          variant="contained"
                          color="secondary"
                          className={classes.createdDateSortButton}
                          endIcon={
                            observationListState.order.direction === 'asc' ? (
                              <ArrowDownwardIcon />
                            ) : (
                              <ArrowUpwardIcon />
                            )
                          }>
                          {observationListState.order.direction === 'asc'
                            ? t('dashboardPage.sortByONewest')
                            : t('dashboardPage.sortByONewest')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                direction="column"
                spacing={1}
                className={classnames({
                  [`${classes.paddingTop}`]:
                    activeTabName === PENDING_OBSERVATIONS_TAB
                })}>
                {tabs.map((tab, index) => (
                  <TabPanel
                    key={index}
                    value={activeTab}
                    index={index}
                    role="tabpanel"
                    hidden={activeTab !== index}
                    id={`scrollable-auto-tabpanel-${index}`}
                    aria-labelledby={`scrollable-auto-tab-${index}`}>
                    {tab.content}
                  </TabPanel>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <AddObservationAiPocDialog
            addObservationDialogIsOpen={addObservationDialogIsOpen}
            toggleAddObservationDialog={toggleAddObservationDialog}
            refetchQuery={resetAndRefetch}
          />
        </Box>
      </main>
    </>
  );
};

ObservationsDashboardAiPocPage.propTypes = {
  queryParams: PropTypes.object.isRequired,
  setQueryParams: PropTypes.func.isRequired
};

export default withAuthorization(
  withQueryParams({
    stripUnknownKeys: false,
    keys: {
      tab: {
        default: undefined,
        validate: (_, props) => validateTabParam(props.location.search)
      }
    }
  })(ObservationsDashboardAiPocPage),
  {
    personnelOnProject: true
  }
);
