import React, { Fragment } from 'react';
import {
  Typography,
  Grid,
  Card,
  useTheme,
  Stack,
  Divider,
  Tooltip,
  Switch
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import withAuthorization from 'hocs/withAuthorization';
import useIsOnline from 'store/onlineDetection';
import IndicatorScoreGauge from 'components/preIncidentIndicators/IndicatorScoreGauge';
import OverallScoreGauge from 'components/preIncidentIndicators/OverallScoreGauge';
import { useQuery } from '@apollo/react-hooks';
import { GET_PRE_INCIDENT_ANALYSIS_FOR_PROJECT } from 'graphql/preIncidentAnalysis';
import { useParams } from 'react-router-dom';
import PageNotFoundNotice from 'components/common/PageNotFoundNotice';
import ErrorNotice from 'components/common/ErrorNotice';
import LoadingSpinner from 'components/common/LoadingSpinner';
import { DateTime } from 'luxon';
import { getScoreIcon } from 'utils/indicatorScoreRange';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import useSettings from 'hooks/useSettings';

const styles = makeStyles(theme => ({
  notOnlineMessage: {
    backgroundColor: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
    borderRadius: 4,
    color: theme.palette.secondary.contrastText
  },
  contentCard: {
    padding: theme.spacing(2)
  },
  overallCardTypography: {
    fontWeight: 'bold'
  },
  categoryShortDescription: {
    fontWeight: 'bold'
  }
}));

const PreIncidentIndicatorsPage = () => {
  const { settings, setSettings } = useSettings();
  const shouldShowNonActionablePii = settings && settings.showNonActionablePii;
  const classes = styles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { isOnline } = useIsOnline();
  const { projectId } = useParams();
  const { data, loading, error } = useQuery(
    GET_PRE_INCIDENT_ANALYSIS_FOR_PROJECT,
    {
      variables: { projectId }
    }
  );
  const preIncidentAnalysis = data?.preIncidentAnalysisForProject;
  const overallScore = preIncidentAnalysis?.probability;
  const topPreIncidentIndicatorsHighestToLowestScore = preIncidentAnalysis?.preIncidentIndicators
    .map(preIncidentIndicator => ({
      ...preIncidentIndicator,
      score: preIncidentIndicator.score
    }))
    // Only take positive scores
    .filter(indicator => indicator.score > 0)
    // Sort higher scores first
    .sort((a, b) => b.score - a.score)
    // Override the changeDescriptor for indicators past the top 5
    .map((indicator, index) => ({
      ...indicator,
      changeDescriptor: index < 5 ? indicator.changeDescriptor ?? '' : 'Extra'
    }))
    // Decide whether to show non-actionable indicators
    .filter(
      indicator =>
        shouldShowNonActionablePii ||
        indicator.preIncidentIndicatorCategory.isActionable
    )
    // Only take the top 5
    .slice(0, 5);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    if (error.message?.includes('not found')) {
      return <PageNotFoundNotice />;
    } else {
      return <ErrorNotice />;
    }
  }

  if (!preIncidentAnalysis) {
    return (
      <Grid container justifyContent="center">
        <Grid item>
          <Card className={classes.contentCard}>
            <Grid
              container
              alignContent="space-between"
              alignItems="center"
              spacing={1}>
              <Grid item>{getScoreIcon({ score: 0.5, theme })}</Grid>
              <Grid item>
                <Typography className={classes.overallCardTypography}>
                  {t('preIncidentIndicatorsPage.noAnalysis')}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    );
  }
  return isOnline ? (
    <Stack alignContent="flex-start" justifyContent="center" spacing={2}>
      <Stack direction="row" justifyContent="center">
        <Card className={classes.contentCard}>
          <Grid
            container
            alignContent="space-between"
            alignItems="center"
            justifyContent="center"
            spacing={1}>
            <Grid item>
              <Typography className={classes.overallCardTypography}>
                {t('preIncidentIndicatorsPage.overallScoreLabel')}
              </Typography>
            </Grid>
            <Grid item>
              <OverallScoreGauge score={overallScore} projectId={projectId} />
            </Grid>
          </Grid>
        </Card>
      </Stack>
      {topPreIncidentIndicatorsHighestToLowestScore.length !== 0 && (
        <Stack direction="row" justifyContent="center">
          <Card className={classes.contentCard}>
            <Stack spacing={2}>
              <Stack direction="row" spacing={1} justifyContent="flex-end">
                <Typography>Actionable</Typography>
                <Switch
                  inputProps={{
                    'data-testid': 'actionable-input-switch',
                    role: 'button',
                    'aria-pressed': shouldShowNonActionablePii,
                    'aria-label': `toggle switch to control whether non-actionable pre-incident indicators should be shown`
                  }}
                  checked={shouldShowNonActionablePii}
                  onChange={event =>
                    setSettings({ showNonActionablePii: event.target.checked })
                  }
                  value={shouldShowNonActionablePii}
                  color="primary"
                  size="small"
                />
                <Typography>All</Typography>
              </Stack>
              {topPreIncidentIndicatorsHighestToLowestScore.map(
                (indicator, index) => (
                  <Fragment key={`indicator_${index}`}>
                    {!!index && <Divider />}
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={1}
                      alignItems="flex-end">
                      <Typography className={classes.categoryShortDescription}>
                        {
                          indicator.preIncidentIndicatorCategory
                            .shortDescription
                        }
                      </Typography>
                      <Stack direction="row" alignItems="end">
                        {indicator.changeDescriptor === 'Same' ? (
                          <Tooltip
                            title={t(
                              'preIncidentIndicatorsPage.rankChangeIndicatorDescription.same'
                            )}>
                            <ArrowForwardIcon
                              sx={{
                                color: 'rankChangeIndicator.same'
                              }}
                            />
                          </Tooltip>
                        ) : indicator.changeDescriptor === 'Up' ? (
                          <Tooltip
                            title={t(
                              'preIncidentIndicatorsPage.rankChangeIndicatorDescription.up'
                            )}>
                            <ArrowUpwardIcon
                              sx={{
                                color: 'rankChangeIndicator.up'
                              }}
                            />
                          </Tooltip>
                        ) : indicator.changeDescriptor === 'Down' ? (
                          <Tooltip
                            title={t(
                              'preIncidentIndicatorsPage.rankChangeIndicatorDescription.down'
                            )}>
                            <ArrowDownwardIcon
                              sx={{
                                color: 'rankChangeIndicator.down'
                              }}
                            />
                          </Tooltip>
                        ) : indicator.changeDescriptor === 'New' ? (
                          <Tooltip
                            title={t(
                              'preIncidentIndicatorsPage.rankChangeIndicatorDescription.new'
                            )}>
                            <PriorityHighIcon
                              sx={{
                                color: 'rankChangeIndicator.new'
                              }}
                            />
                          </Tooltip>
                        ) : (
                          indicator.changeDescriptor === 'Extra' && (
                            <Tooltip
                              title={t(
                                'preIncidentIndicatorsPage.rankChangeIndicatorDescription.extra'
                              )}>
                              <HorizontalRuleIcon
                                sx={{
                                  color: 'rankChangeIndicator.extra'
                                }}
                              />
                            </Tooltip>
                          )
                        )}
                        <IndicatorScoreGauge indicator={indicator} />
                      </Stack>
                    </Stack>
                  </Fragment>
                )
              )}
            </Stack>
          </Card>
        </Stack>
      )}
      <Stack direction="row" justifyContent="center">
        <Card className={classes.contentCard}>
          <Stack spacing={2}>
            <Typography>
              {t('preIncidentIndicatorsPage.lastAnalysisDateDescription', {
                lastAnalysisDate: DateTime.fromISO(
                  preIncidentAnalysis.lastAnalysisDate
                ).toFormat('MM/dd/yyyy')
              })}
            </Typography>
            {topPreIncidentIndicatorsHighestToLowestScore.length === 0 && (
              <Typography>
                {t('preIncidentIndicatorsPage.noIndicatorsDescription')}
              </Typography>
            )}
            {topPreIncidentIndicatorsHighestToLowestScore.map(
              (indicator, index) => {
                const category = indicator.preIncidentIndicatorCategory;
                return (
                  <Fragment key={`indicator_definition_${index}`}>
                    <Divider />
                    <Stack direction="row" spacing={1}>
                      <Typography className={classes.categoryShortDescription}>
                        {category.shortDescription + ':'}
                      </Typography>
                      <Typography>{category.description}</Typography>
                    </Stack>
                  </Fragment>
                );
              }
            )}
          </Stack>
        </Card>
      </Stack>
    </Stack>
  ) : (
    <Typography className={classes.notOnlineMessage}>
      {t('preIncidentIndicatorsPage.unavailableOffline')}
    </Typography>
  );
};

export default withAuthorization(PreIncidentIndicatorsPage);
